/* You can add global styles to this file, and also import other style files */

@import "../src/assets/css/nucleo-icons.css";
@import "../src/assets/css/material-kit.css";
@import 'material-icons/iconfont/material-icons.css';
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~open-iconic/font/css/open-iconic-bootstrap.css';
@import "~@ng-select/ng-select/themes/default.theme.css";


#backToTopButton {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #333;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 50%;
}

#backToTopButton:hover {
  background-color: #555;
}




