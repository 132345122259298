@font-face {
	font-family: 'Roboto';
	src: url('../font/roboto/Roboto-Regular.eot?10000');
	src:
		url('../font/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
		url('../font/roboto/Roboto-Regular.woff2') format('woff2'),
		url('../font/roboto/Roboto-Regular.woff') format('woff'),
		url('../font/roboto/Roboto-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto - Bold';
	src: url('../font/roboto/Roboto-Bold.eot?10000');
	src:
		url('../font/roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
		url('../font/roboto/Roboto-Bold.woff2') format('woff2'),
		url('../font/roboto/Roboto-Bold.woff') format('woff'),
		url('../font/roboto/Roboto-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
